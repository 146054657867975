@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  height: 100%;
  width: 100%;
}
